import * as TYPES from "./actionTypes";

const INIT_STATE = {
  loading: false,
  contact: {},
  error: "",
  highestDividedList: [],
  topGainerList: [],
  topLooserList: [],
  industryList: [],
  popularStockList: [],
  favouriteStockList: [],
};

const Reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_HOME:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_HOME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.GET_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        highestDividedList: action.payload,
      };

    case TYPES.TOP_GAINER:
      return {
        ...state,
        loading: true,
      };

    case TYPES.TOP_GAINER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.TOP_GAINER_SUCCESS:
      return {
        ...state,
        loading: false,
        topGainerList: action.payload,
      };

    case TYPES.TOP_LOOSER:
      return {
        ...state,
        loading: true,
      };

    case TYPES.TOP_LOOSER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.TOP_LOOSER_SUCCESS:
      return {
        ...state,
        loading: false,
        topLooserList: action.payload,
      };

    case TYPES.INDUSTRIES:
      return {
        ...state,
        loading: true,
      };

    case TYPES.INDUSTRIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.INDUSTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        industryList: action.payload,
      };

    case TYPES.POPULARSTOCK:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POPULARSTOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.POPULARSTOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        popularStockList: action.payload,
      };
    case TYPES.FAVOURITE_STOCK:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAVOURITE_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.FAVOURITE_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        favouriteStockList: action.payload,
      };

    case TYPES.ADD_REMOVE_FAVOURITE_STOCK:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_REMOVE_FAVOURITE_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.ADD_REMOVE_FAVOURITE_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default Reducer;
