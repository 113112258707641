import { post, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);
export const getSettings = () => get(url.GET_SETTINGS);

// Register
export const postRegister = (data) => post(url.POST_REGISTER, data);

//kyc
export const submitKyc = (data) => post(url.POST_KYC_PROFILE, data);
//change password
export const putChangePassword = (data) => post(url.POST_CHANGE_PASSWORD, data);

//HOME
export const homeAPI = (data) => get(url.MAIL_HOME, data);
export const topGainerApi = (data) => get(url.TOP_GAINER_API, data);
export const topLooserApi = (data) => get(url.TOP_LOSSER_API, data);
export const industryApi = (data) => post(url.INDUSTRY_API, data);
export const popularStockApi = (data) => get(url.POPULAR_STOCK_API, data);
export const favouriteStockApi = (data) => post(url.FAVOURITE_STOCK_API, data);
export const addRemovefavouriteStockApi = (data) =>
  post(url.ADD_REMOVE_FAVOURITE_STOCK_API, data);
export const getNotification = (data) => post(url.NOTIFICATION_API, data);
export const getMessageApi = (data) => post(url.APP_MESSAGE_API, data);
export const getHelpSupport = (data) => post(url.HELP_SUPPORT_API, data);
export const getAllStock = (data) => post(url.ALL_STOCK_API, data);
export const openPositionApi = (data) => get(url.OPEN_POSITION_API, data);
export const pendingPositionApi = (data) =>
  post(url.PENDING_POSITION_API, data);
export const scheduleOrderApi = (data) => post(url.SCHEDULE_ORDER_API, data);
export const orderHistoryApi = (data) =>
  get(
    `${url.ORDER_HISTORY_API}?page=${data.page}&order=${data.order}&oredrBy=${data.orderBy}&search=${data.search}&limit=${data.limit}&startDate=${data.startDate}&endDate=${data.endDate}`
  );
export const tradingActivityApi = (data) => get(url.TRADING_ACTIVITY_API, data);
export const stockDetailApi = (data) => get(`${url.STOCK_DETAIL_API}/${data}`);

//  File
export const postFile = (data) => {
  const formData = new FormData();
  formData.append("file", data);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return post(url.POST_FILE, formData, config);
};

export const postIpfsFile = (data) => {
  const formData = new FormData();
  Object.entries(data).map(([key, value]) => formData.append(key, value));
  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_IPFS_FILE, formData, config);
};

// User
export const getCurrentUser = () => get(url.GET_USER_PROFILE);
export const putProfileApi = (data) => post(url.PUT_PROFILE, data);
export const putProfileImage = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.PUT_PROFILE_IMAGE, formData, config);
};
export const getUserByWallet = (walletAddress) =>
  get(url.GET_USER_BY_WALLET + walletAddress);

// Homepage
export const getHomepage = (data) => post(url.GET_HOMEPAGE, data);
export const getMostlyView = (data) => post(url.GET_MOSTLY_VIEW, data);
export const getLatest = (data) => post(url.GET_LATEST, data);
export const getTopCollections = (data) => post(url.GET_TOP_COLLECTIONS, data);
export const getExplore = (data) => post(url.GET_EXPLORE, data);

// TEMAS
export const getTeams = () => get(url.GET_TEAMS);

// Faq
export const getFaqs = () => get(url.GET_FAQS);

// Content Pages
export const getContentPage = (type) =>
  get(url.GET_CONTENT_PAGE + "?type=" + type);

// Contact Us
export const postContactUs = (data) => post(url.POST_CONTACT_US, data);
export const getNotifications = (data) => post(url.GET_NOTIFICATIONS, data);

export const sendOtp = (data) => post(url.SEND_OTP, data);
export const sendOtpForMobile = (data) => post(url.SEND_OTP_MOBILE, data);
export const verifyOtp = (data) => post(url.VERIFY_OTP, data);
export const verifyOtpForPWD = (data) => post(url.VERIFY_OTP_FOR_FPWD, data);

export const signUp = (data) => post(url.SIGNUP_AND_CREATE_WALLET, data);
export const generatemnemonic = (data) => post(url.GENERATE_MNEMONIC, data);
export const generatewallet = (data) => post(url.GENERATE_WALLET, data);
export const onSearchCoin = (data) => post(url.SEARCH_COIN, data);
export const onGetCryptoList = (mpin) => post(url.ON_GET_CRYPTO_LIST, mpin);

export const sendTransactions = (data) => post(url.SEND_TRANSACTION, data);
export const getHistoryApi = (data) => post(url.GET_HISTORY, data);
export const forgotMpin = () => get(url.FORGOT_MPIN);

export const verifyPassword = (data) => post(url.VERIFY_PASSWORD, data);
export const verifyWalletAdress = (data) =>
  post(url.ON_VERFIY_WALLET_ADDRESS, data);

export const apironeCreateAccount = (data) =>
  post(url.SIGNUP_AND_CREATE_WALLET, data);

//get crypto chain list

export const getWalletList = () => get(url.ON_GET_CRYPTO_LIST);

// Launchpad
export const getLaunchpad = (id) => get(url.GET_LAUNCHPAD + "/" + id);
export const postLaunchpad = (data) => post(url.POST_LAUNCHPAD, data);
export const postLaunchpadWhitelist = (id, data) =>
  post(url.POST_LAUNCHPAD_WHITELIST + "/" + id, data);

//OTP
export const sendOTP = (data) => post(url.SEND_OTP, data);
export const verifyOTP = (data) => post(url.VERIFY_OTP, data);

//forgot password
export const forgotPassword = (data) => post(url?.FORGOT_PASSWORD, data);
export const resetPassword = (data) => post(url.REST_PASSWORD, data);

//contact
export const postContactForm = (data) => post(url.POST_CONTACT_FORM, data);
//cms
export const getCMS = (id) => get(url.GET_CMS_CONTENT);
export const truestedContent = (data) => post(url.TRUSTED_CONTENT_API, data);

//profile
export const getProfileApi = (data) => get(url.GET_PROFILE, data);
//profile
export const getMarketDetails = (data) => get(url.GET_MARKET_DETAILS, data);
//contact us
export const submitContact = (data) => post(url.POST_CONTACT, data);
//forgot password
export const postForgetPwd = (data) => post(url.FORGOT_PASSWORD, data);
//verify otp
export const verifyOtpApi = (data) => post(url.VERIFY_OTP, data);
export const resendOtpApi = (data) => post(url.RESEND_OTP, data);
export const resendPassApi = (data) => post(url.REST_PASSWORD, data);
//crypto price
export const getCryptoPrice = (data) =>
  get(url.CRYPTO_PRICE + `?fsyms=${data.fsyms}&tsyms=${data.tsyms}`);
//coins
export const getCoinPrice = (data) => post(url.GET_COINS_PRICE, data);

//AFFILIATE

export const BECOME_AFFILIATE_REQUEST = (data) =>
  post(url.BECOME_AFFILIATE_REQUEST, data);
export const AFFILIATE_TRANSACTION_LIST = (data) =>
  post(url.AFFILIATE_TRANSACTION_LIST, data);
export const AFFILIATE_CLIENT_LIST = (data) =>
  post(url.AFFILIATE_CLIENT_LIST, data);

export const STOCK_BUY_SELL = (data) => post(url.STOCK_BUY_SELL, data);
