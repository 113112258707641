import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";

// img
import Loader from "components/Loader";
import { Link, useParams } from "react-router-dom";
import c1 from "../../../Assets/images/c2.png";

import StockDetailPop from "../../Modals/StockDetailPop";

//store
import { useDispatch, useSelector } from "react-redux";
import { stockSingleDetial } from "store/actions";
import { TVChartContainer } from "../../TVChartContainer";
import {
  limitOrderTypesListStatic,
  orderTypesStatic,
  purchaseTypesStatic,
} from "helpers/Mocks";
import { CONSTANT } from "helpers/constant";
import { handleDecimal } from "helpers/helper";
import StockPaymentBox from "./StockPaymentBox";
import { toast } from "react-toastify";
import { STRINGS } from "helpers/String";

const StockOrderBox = () => {
  const [successPop, setSuccessPop] = useState();
  const [step, setStep] = useState(1);
  const { stockView: stockDetails, loading } = useSelector(
    (state) => state?.Basic
  );

  const [paymentScreenDetails, setPaymentScreenDetails] = useState({
    amount: "",
    qty: "",
    type: "",
    timeInForce: "",
    orderType: "",
    estimatedAmount: "",
    limitOrderPrice: "",
    isOngoingOrder: false,
  });

  const stockPrice = handleDecimal(stockDetails?.price);

  const [state, setState] = useState({
    walletBalance: "1,000,000.00",
    orderTypeslist: orderTypesStatic,
    // selectedOrderType: orderTypesStatic[0].value,
    selectedOrderType: CONSTANT.Market,
    purchaseTypesList: purchaseTypesStatic,
    selectePurchaseType: purchaseTypesStatic[1].value,
    limitOrderTypesList: limitOrderTypesListStatic,
    selectedLimitedOrderType: limitOrderTypesListStatic[0].value,
    monetaryAmount: stockPrice, // disabled when purchaseType is "share" | can be altered otherwise
    shareNumber: 1, // disabled when orderType is market and show shared calculated based on monetary amount
    estimatedAmount: stockPrice,
    dailyVolume: 70,
    dailyChange: 56,
    earningsPerShare: 45,
    limitOrderPrice: handleDecimal(stockPrice - (10 / 100) * stockPrice),
    isOngoingOrder: false,
  });

  useEffect(() => {
    setState((p) => ({
      ...p,
      limitOrderPrice: handleDecimal(stockPrice - (10 / 100) * stockPrice),
      estimatedAmount: stockPrice,
      monetaryAmount: stockPrice,
    }));
  }, [stockPrice]);

  const {
    orderTypeslist,
    selectedOrderType,
    purchaseTypesList,
    selectePurchaseType,
    monetaryAmount,
    shareNumber,
    limitOrderPrice,
    estimatedAmount,
    limitOrderTypesList,
    selectedLimitedOrderType,
    isOngoingOrder,
  } = state;

  useEffect(() => {
    if (isOngoingOrder) {
      let newPurchaseList = purchaseTypesList.filter(
        (item) => item.value === CONSTANT.Money
      );
      setState((prevState) => ({
        ...prevState,
        purchaseTypesList: newPurchaseList,
        selectePurchaseType: newPurchaseList[0].value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        purchaseTypesList: purchaseTypesStatic,
      }));
    }
  }, [isOngoingOrder]);

  const isDecimal = (num) => num !== Math.floor(num);

  const _onChangeText = (name, value) => {
    let updatedKeys = {};
    console.log(value, "stockPrice");
    if (name == "monetaryAmount") {
      let shares;
      if (selectedOrderType === CONSTANT.Limit) {
        shares = handleDecimal(value) / limitOrderPrice;
      } else if (selectedOrderType === CONSTANT.Market) {
        shares = handleDecimal(value) / stockPrice;
      }

      console.log(selectePurchaseType, "select Purchase type");
      if (selectePurchaseType == CONSTANT.Money) {
        console.log(shares, "Shares");
        updatedKeys["shareNumber"] = handleDecimal(shares, 9);
      }

      updatedKeys["estimatedAmount"] = handleDecimal(value);
      updatedKeys[name] = handleDecimal(value);
    } else if (name == "shareNumber") {
      // On Change Share Number
      let monetaryAmountCalculated;
      if (selectedOrderType === CONSTANT.Limit) {
        monetaryAmountCalculated = limitOrderPrice * handleDecimal(value, 9);
      } else {
        monetaryAmountCalculated = stockPrice * handleDecimal(value, 9);
      }

      if (selectePurchaseType === CONSTANT.Share) {
        if (selectedOrderType === CONSTANT.Limit) {
          updatedKeys["monetaryAmount"] = handleDecimal(
            monetaryAmountCalculated
          );
        } else if (selectedOrderType === CONSTANT.Market) {
          updatedKeys["monetaryAmount"] = handleDecimal(
            monetaryAmountCalculated
          );
        } else {
        }
      }

      updatedKeys[name] = handleDecimal(value, 9);
      updatedKeys["estimatedAmount"] = handleDecimal(monetaryAmountCalculated);
      updatedKeys["selectedLimitedOrderType"] = isDecimal(parseFloat(value))
        ? limitOrderTypesListStatic[1].value
        : limitOrderTypesListStatic[0].value;
    } else if (name === "limitOrderPrice") {
      updatedKeys["estimatedAmount"] = handleDecimal(0);
      updatedKeys["shareNumber"] = handleDecimal(0);
      updatedKeys["monetaryAmount"] = handleDecimal(0);
      // let shares;
      // let monetaryAmountCalculated;
      //   monetaryAmountCalculated = limitOrderPrice * handleDecimal(value, 9);
      // updatedKeys['estimatedAmount'] = handleDecimal(monetaryAmountCalculated);
    }

    setState((prev) => ({
      ...prev,
      ...updatedKeys,
      [name]: value,
    }));
  };
  const onSelectDropdownWithKey = (value, stateKey) => {
    setState((prev) => ({
      ...prev,
      [stateKey]: value,
    }));
  };
  const onChangeTextWithKey = (key, value) => {
    _onChangeText(key, value);
  };

  const onSelectDropdown = (value, stateKey) => {
    console.log(value, "value");
    setState((prev) => ({
      ...prev,
      [stateKey]: value,
    }));
  };

  const handleCommonPurchaseType = (text) => {
    selectePurchaseType === CONSTANT.Share
      ? onChangeTextWithKey("shareNumber", text)
      : onChangeTextWithKey("monetaryAmount", text);
  };

  const onPressBuy = () => {
    // Toast.show(STRINGS.otpSent, {
    //   type: 'success',
    // });
    /* Check min. trade value */

    if (estimatedAmount < 1) return toast.error("Mininum trade value is $1");

    // check if shareNumber is fractional value and is allowed by asset or not
    if (!stockDetails?.fractionable && isDecimal(parseFloat(shareNumber)))
      return toast.error("This stock does not allow fractionable quantity.");

    // console.log('selectedLimitedOrderType : ', selectedLimitedOrderType);
    // Limit orders as fractions cannot be sent as "gtc"
    if (
      selectedOrderType == CONSTANT.Limit &&
      selectedLimitedOrderType == "gtc" &&
      isDecimal(parseFloat(shareNumber))
    )
      return toast.error(
        "Limit orders with fractionable quantity are not supported for GTC orders."
      );

    setStep(2);

    setPaymentScreenDetails((p) => ({
      amount: monetaryAmount,
      qty: shareNumber,
      type: "BUY",
      timeInForce: selectedLimitedOrderType,
      orderType: selectedOrderType,
      estimatedAmount,
      limitOrderPrice,
      isOngoingOrder,
    }));
  };
  return (
    <>
      <StockDetailPop stockDetail={successPop} setStockDetail={setSuccessPop} />
      <div className="py-2">
        <div className="cardCstm box mt-3 px-3 py-3">
          {step == 1 ? (
            <>
              <div className="top py-2">
                <p className="m-0 fw-sbold  ">Type of order</p>

                <div className="d-flex align-items-center gap-10 mt-3 navPillsTab navPillsTab2 ">
                  <Button
                    onClick={() =>
                      setState((p) => ({
                        ...p,
                        selectedOrderType: CONSTANT.market,
                      }))
                    }
                    className={`${
                      selectedOrderType == CONSTANT.market && "active"
                    } nav-link d-flex align-items-center justify-content-center  w-50 rounded-pill`}
                  >
                    Market
                  </Button>
                  <Button
                    onClick={() =>
                      setState((p) => ({
                        ...p,
                        selectedOrderType: CONSTANT.Limit,
                      }))
                    }
                    className={`${
                      selectedOrderType == CONSTANT.Limit && "active"
                    } nav-link d-flex align-items-center justify-content-center  w-50 rounded-pill`}
                  >
                    Limit
                  </Button>
                </div>
              </div>
              <div className="contentBody py-2">
                <Form>
                  <div className="py-2">
                    <p className="m-0   fw-sbold pb-2">Type of Purchase</p>
                    <Form.Select
                      className="form-control bg-transparent fw-sbol mb-2"
                      aria-label="Default select example"
                      onChange={(e) => {
                        onSelectDropdown(e.target.value, "selectePurchaseType");
                      }}
                      value={selectePurchaseType}
                    >
                      {purchaseTypesStatic.map((type) => {
                        return (
                          <option
                            style={{
                              background: "black",
                            }}
                            value={type.value}
                          >
                            {type.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <input
                      type="number"
                      placeholder={
                        selectePurchaseType === "Money" ? "Money" : "Shares"
                      }
                      value={
                        selectePurchaseType === "Money"
                          ? monetaryAmount
                          : shareNumber
                      }
                      onChange={(e) => handleCommonPurchaseType(e.target.value)}
                      className="form-control bg-transparent"
                    />
                    <p className="m-0 text-muted fw-sbold text-end">
                      {selectePurchaseType === "Money" ? "Shares" : "Amount"} :{" "}
                      {selectePurchaseType === "Money"
                        ? shareNumber
                        : monetaryAmount}
                    </p>
                  </div>
                  {selectedOrderType == CONSTANT.Limit && (
                    <>
                      <Row className="pb-2">
                        <Col className="col-6 mb-2">
                          <p className="m-0   fw-sbold pb-2">
                            Limit Order Type
                          </p>
                          <Form.Select
                            className="form-control bg-transparent fw-sbol"
                            aria-label="Default select example"
                            onChange={(e) =>
                              onSelectDropdownWithKey(
                                e.target.value,
                                "selectedLimitedOrderType"
                              )
                            }
                            value={selectedLimitedOrderType}
                          >
                            {limitOrderTypesListStatic.map((type) => {
                              return (
                                <option
                                  style={{
                                    background: "black",
                                  }}
                                  value={type.value}
                                >
                                  {type.label}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                        <Col className="col-6">
                          <p className="m-0   fw-sbold pb-2">
                            Limit/Stop Price
                          </p>

                          <input
                            type="number"
                            value={limitOrderPrice}
                            onChange={(e) =>
                              onChangeTextWithKey(
                                "limitOrderPrice",
                                e.target.value
                              )
                            }
                            placeholder="13.63"
                            className="form-control bg-transparent fw-sbold"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <div className="py-2">
                    <div
                      className="d-flex align-items-center justify-content-between gap-10 px-2 py-2 rounded"
                      style={{ background: "#222" }}
                    >
                      <p className="m-0 text-muted fw-sbold">
                        Estimated Amount
                      </p>
                      <p className="m-0   fw-sbold">${estimatedAmount}</p>
                    </div>
                  </div>
                  {selectedOrderType === CONSTANT.Market && (
                    <div className="py-2">
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="order"
                          checked={isOngoingOrder}
                          onChange={() =>
                            _onChangeText("isOngoingOrder", !isOngoingOrder)
                          }
                          className="form-check me-2"
                        />
                        <label
                          htmlFor="order"
                          className="form-label m-0 text-muted"
                        >
                          Make it an ongoing order instead.
                        </label>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
              <div className="btnWrpper mt-5 py-2">
                <Button
                  onClick={onPressBuy}
                  disabled={!monetaryAmount || !shareNumber}
                  className="d-flex w-100 rounded-pill align-items-center justify-content-center commonBtn fw-sbold"
                >
                  Buy
                </Button>
              </div>
            </>
          ) : step == 2 ? (
            <StockPaymentBox
              paymentScreenDetails={paymentScreenDetails}
              setStep={setStep}
              setSuccessPop={setSuccessPop}
              setMarketTab={() =>
                setState((p) => ({
                  ...p,
                  selectedOrderType: CONSTANT.market,
                }))
              }
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default StockOrderBox;
