import React, { useEffect, useRef, useState} from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";

// css
import styles from "./StockHistory.module.scss";
import PurchasedStocks from "./component/PurchasedStocks";
import SoldStocks from "./component/SoldStocks";

import s1 from "../../../Assets/images/s1.png";
//store
import {  allStockList } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Loader";
//pagination
import ReactPagination from "../../../components/Common/ReactPagination";
import { itemsPerPage } from "helpers/helper";


const StockHistory = () => {
  const dispatch = useDispatch()

  const {allStocks, loading} = useSelector((state) => state?.Basic);
  const [filter, setFilter] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const indexOfLastItem = filter?.page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
  allStocks?.length > 0 &&
  allStocks?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    dispatch(allStockList())
  }, [filter])
  // console.log(allStocks, "allStocks")
  return (
    
    <>
     {loading && 
      <Loader /> 
    }
      <section className="stockHistory position-relative py-2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="pageHead">
                <h4 className="m-0 fw-sbold ">All Stocks</h4>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="box p-0 tableCard">
                <div className="table-responsive commonTable">
                  <table className="table m-0">
                    <thead>
                      <tr>
                        <th className="  fw-sbold">Stocks</th>
                        <th className="  fw-sbold">Price</th>
                        <th className="  fw-sbold">24 Change</th>
                      </tr>
                    </thead>
                    <tbody>
                    {currentItems?.length>0 && currentItems?.map((list, i) => { 
                      return(
                      <tr key={i}>
                        <td className="border-0 py-3 ">
                          <div className="d-flex align-items-center gap-10">
                            <div className="imgWrp">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                              >
                                <rect
                                  width="40"
                                  height="40"
                                  rx="5"
                                  fill="white"
                                />
                                <g clip-path="url(#clip0_18_68)">
                                  <path
                                    d="M6 25.627H12.7408V27.6212H10.807V32.414H7.99815V27.6212H6V25.627ZM16.1547 28.3692L14.7355 32.414H12.038L14.6836 25.627H17.6131L20.3225 32.414H17.5918L16.1547 28.3692ZM19.6781 25.627H26.4186V27.6212H24.4854V32.414H21.6767V27.6212H19.6781V25.627ZM29.8337 28.3692L28.4139 32.414H25.7164L28.3629 25.627H31.2916L34 32.414H31.2703L29.8337 28.3692Z"
                                    fill="#4C66FF"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M29.9331 13.1605C29.6814 12.6793 29.35 12.2181 28.9376 11.7857C28.0109 10.8133 26.7035 10.0042 25.1562 9.44612C23.5962 8.88375 21.8169 8.58618 20.0088 8.58618C18.2006 8.58618 16.4213 8.88375 14.8621 9.44612C13.3141 10.0042 12.0065 10.8134 11.0799 11.7857C10.6678 12.2181 10.3352 12.6799 10.0836 13.1613C12.098 12.6878 15.5436 12.0625 18.7429 11.9964C19.0515 11.99 19.264 12.0861 19.4039 12.2584C19.5743 12.4684 19.5615 13.2167 19.5573 13.5515L19.4664 22.2044C19.6466 22.2102 19.8276 22.214 20.0088 22.214C20.1915 22.214 20.3735 22.2111 20.5537 22.2053L20.4627 13.5514C20.458 13.2167 20.4448 12.4683 20.6158 12.2584C20.7561 12.0861 20.9678 11.99 21.2763 11.9964C24.4743 12.0619 27.9192 12.6873 29.9331 13.1605Z"
                                    fill="#4C66FF"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M30.3632 14.2857C27.415 13.6377 25.4462 13.5179 23.5036 13.4131C21.8102 13.3216 21.7881 13.9094 21.9572 15.0328C21.9682 15.1014 21.9818 15.1878 21.998 15.2863C22.5659 18.5569 23.2732 21.3713 23.3948 21.849C27.523 20.9316 30.5001 18.3881 30.5001 15.4002C30.5001 15.0239 30.4533 14.6512 30.3632 14.2857ZM18.062 15.0328C18.2312 13.9095 18.2095 13.3216 16.5164 13.4131C14.5731 13.5179 12.6038 13.6377 9.65448 14.2861C9.56439 14.6515 9.51703 15.0239 9.51703 15.4001C9.51703 16.6915 10.0574 17.9411 11.0799 19.0147C12.0066 19.9871 13.3141 20.7962 14.8622 21.3546C15.4225 21.5563 16.0137 21.7216 16.6228 21.8538C16.7371 21.4063 17.4606 18.5333 18.0348 15.2042C18.0454 15.1405 18.0552 15.0816 18.062 15.0328Z"
                                    fill="#4C66FF"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_18_68">
                                    <rect
                                      width="28"
                                      height="25"
                                      fill="white"
                                      transform="translate(6 8)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg> */}
                            </div>
                            <img src={list?.imgUrlDark} className="stock-list-size" />
                            <div className="content">
                              <h6 className=" m-0 fw-bold pb-2">{list?.symbol}</h6>
                              <p className="text-muted m-0">{list?.shortName}</p>
                            </div>
                          </div>
                        </td>
                        <td className="border-0 py-3 ">$ {list?.price ? parseFloat(list?.price).toFixed(2) : '0'}</td>
                        <td className="border-0 py-3 ">
                          <p className="m-0 themeClr fw-sbold">{list?.changePercentage ? parseFloat(list?.changePercentage).toFixed(2) : '0'}%</p>
                        </td>
                      </tr>
                       )
                      })}
                    </tbody>
                  </table>
                  {allStocks?.length>0 &&
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={allStocks?.length}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  }
                </div>
              </div>
              {/* <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="PurchasedStocks"
              >
                <Nav
                  variant="pills"
                  className={`${styles.tabList} mb-3 tabPillsBar justify-content-around`}
                >
                  <Nav.Item className="w-50">
                    <Nav.Link
                      className={`${styles.link} w-100 d-flex align-items-center justify-content-center  fw-sbold`}
                      eventKey="PurchasedStocks"
                    >
                      Purchased Stocks
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="w-50">
                    <Nav.Link
                      className={`${styles.link} w-100 d-flex align-items-center justify-content-center  fw-sbold`}
                      eventKey="SoldStocks"
                    >
                      Sold Stocks
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="PurchasedStocks">
                    <PurchasedStocks />
                  </Tab.Pane>
                  <Tab.Pane eventKey="SoldStocks">
                    <SoldStocks />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default StockHistory;
