import React from "react";

// img
import c1 from "../../../../Assets/images/c1.png";
import { Col, Nav, Row, Tab } from "react-bootstrap";

const HistoricalTransaction = () => {
  return (
    <>
      <div className="py-2">
        <Tab.Container id="left-tabs-example" defaultActiveKey="Completed">
          <Nav
            variant="pills"
            className={` navPillsTab navPillsTab2 gap-10 mt-3`}
          >
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Completed"
              >
                Completed
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Pending"
              >
                Scheduled
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="Pending">
              <div className="py-2">
                <p className="m-0  fw-sbold">Select your date Range</p>
                <Row>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    <input
                      type="date"
                      className="form-control box "
                      style={{ fontSize: 12 }}
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    <input
                      type="date"
                      className="form-control box "
                      style={{ fontSize: 12 }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="py-2">
                {" "}
                <div
                  className="box rounded cardCstm my-3"
                  style={{ fontSize: 10, lineHeight: "12px" }}
                >
                  <div className="py-5 text-center">
                    <h6 className="m-0  fw-sbold">No Data available</h6>
                  </div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="Completed">
              <div className="py-2">
                <p className="m-0  fw-sbold">Select your date Range</p>
                <Row>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    <input
                      type="date"
                      className="form-control box "
                      style={{ fontSize: 12 }}
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    <input
                      type="date"
                      className="form-control box "
                      style={{ fontSize: 12 }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="py-2">
                <ul className="list-unstyled ps-0 mb-0">
                  <li className="py-2">
                    <div
                      className="box rounded cardCstm"
                      style={{ fontSize: 10, lineHeight: "12px" }}
                    >
                      <div className=" p-2 top d-flex align-items-center justify-content-between gap-10">
                        <div className="left d-flex align-items-center gap-10">
                          <div
                            className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0 border border-secondary rounded"
                            style={{ height: 40, width: 40 }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="24"
                              viewBox="0 0 20 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_0_2630)">
                                <path
                                  d="M19.3636 8.1816C19.2211 8.2896 16.7052 9.6744 16.7052 12.7536C16.7052 16.3152 19.9066 17.5752 20.0025 17.6064C19.9877 17.6832 19.4939 19.332 18.3145 21.012C17.2629 22.4904 16.1646 23.9664 14.4939 23.9664C12.8231 23.9664 12.3931 23.0184 10.4644 23.0184C8.58477 23.0184 7.91646 23.9976 6.38821 23.9976C4.85995 23.9976 3.79361 22.6296 2.56757 20.9496C1.14742 18.9768 0 15.912 0 13.0032C0 8.3376 3.10565 5.8632 6.16216 5.8632C7.78624 5.8632 9.14005 6.9048 10.1597 6.9048C11.1302 6.9048 12.6437 5.8008 14.4914 5.8008C15.1916 5.8008 17.7076 5.8632 19.3636 8.1816ZM13.6143 3.8256C14.3784 2.94 14.9189 1.7112 14.9189 0.4824C14.9189 0.312 14.9042 0.1392 14.8722 0C13.629 0.0456 12.1499 0.8088 11.258 1.8192C10.5577 2.5968 9.90418 3.8256 9.90418 5.0712C9.90418 5.2584 9.93612 5.4456 9.95086 5.5056C10.0295 5.52 10.1572 5.5368 10.285 5.5368C11.4005 5.5368 12.8034 4.8072 13.6143 3.8256Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_0_2630">
                                  <rect width="20" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <img src="" alt="" className="img-fluid" />
                          </div>
                          <div className="content ">
                            <h6 className="m-0" style={{ color: "#82C3FF" }}>
                              Wallet money update
                            </h6>
                            <p className="m-0  fw-bold pt-1 m-0">1/7/2021</p>
                          </div>
                        </div>
                        <div className="right text-end">
                          <h6 className="m-0 themeGreen fw-sbold">+$457</h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div
                      className="box rounded cardCstm"
                      style={{ fontSize: 10, lineHeight: "12px" }}
                    >
                      <div className=" p-2 top d-flex align-items-center justify-content-between gap-10">
                        <div className="left d-flex align-items-center gap-10">
                          <div
                            className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0 border border-secondary rounded"
                            style={{ height: 40, width: 40 }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="24"
                              viewBox="0 0 20 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_0_2630)">
                                <path
                                  d="M19.3636 8.1816C19.2211 8.2896 16.7052 9.6744 16.7052 12.7536C16.7052 16.3152 19.9066 17.5752 20.0025 17.6064C19.9877 17.6832 19.4939 19.332 18.3145 21.012C17.2629 22.4904 16.1646 23.9664 14.4939 23.9664C12.8231 23.9664 12.3931 23.0184 10.4644 23.0184C8.58477 23.0184 7.91646 23.9976 6.38821 23.9976C4.85995 23.9976 3.79361 22.6296 2.56757 20.9496C1.14742 18.9768 0 15.912 0 13.0032C0 8.3376 3.10565 5.8632 6.16216 5.8632C7.78624 5.8632 9.14005 6.9048 10.1597 6.9048C11.1302 6.9048 12.6437 5.8008 14.4914 5.8008C15.1916 5.8008 17.7076 5.8632 19.3636 8.1816ZM13.6143 3.8256C14.3784 2.94 14.9189 1.7112 14.9189 0.4824C14.9189 0.312 14.9042 0.1392 14.8722 0C13.629 0.0456 12.1499 0.8088 11.258 1.8192C10.5577 2.5968 9.90418 3.8256 9.90418 5.0712C9.90418 5.2584 9.93612 5.4456 9.95086 5.5056C10.0295 5.52 10.1572 5.5368 10.285 5.5368C11.4005 5.5368 12.8034 4.8072 13.6143 3.8256Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_0_2630">
                                  <rect width="20" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <img src="" alt="" className="img-fluid" />
                          </div>
                          <div className="content ">
                            <h6 className="m-0" style={{ color: "#82C3FF" }}>
                              Wallet money update
                            </h6>
                            <p className="m-0  fw-bold pt-1 m-0">1/7/2021</p>
                          </div>
                        </div>
                        <div className="right text-end">
                          <h6 className="m-0 themeGreen fw-sbold">+$457</h6>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default HistoricalTransaction;
