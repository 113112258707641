const moment = require("moment");

export const itemsPerPage = 5;

export const dateFormat = (date) => {
  const formattedDate = moment(date).format("DD MMMM YYYY");
  return formattedDate;
};
export const formatDate = (date) => {
  const formattedDate = moment(date).format("D MMMM YYYY, hh:mm A");
  return formattedDate;
};


export const formatStandardNumberWithMinNMaxDecimal = (
  number = 0,
  minDigits = 2,
  maxDigits = 2,
) => {
  return number.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
};


export const handleDecimal = (number, decimalPlaces = 2) => {
  // Check if the input is not a number
  if (isNaN(number)) return '0';

  // Convert the input to a number and fix it to the specified decimal places
  const fixedNumber = Number(number).toFixed(decimalPlaces);

  // Remove unnecessary trailing zeros and return
  return parseFloat(fixedNumber).toString();
};

export const handleDecimalToViewOnly = (number, decimalPlaces = 2) => {
  // Check if the input is not a number
  if (isNaN(number)) return '0.00';

  // Convert the input to a number and fix it to the specified decimal places
  const fixedNumber = Number(number).toFixed(decimalPlaces);

  // Return the number with specified decimal places (retaining trailing zeros)
  return fixedNumber;
};
