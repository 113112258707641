import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTION from "./actions";

import { postLogin, getCurrentUser, getSettings } from "helpers/backend_helper";
import { updateToken } from "helpers/api_helper";
import { toast } from "react-toastify";

function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(postLogin, user);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(ACTION.apiError(response));
    }
    navigate && navigate(response);
    localStorage.setItem("authUser", JSON.stringify(response?.data));
    localStorage.setItem("token", response?.data?.token);
    localStorage.setItem("customerid", JSON.stringify(response?.data?._id));
    updateToken(response.data);
    yield put(ACTION.loginSuccess(response));
  } catch (error) {
    yield put(ACTION.apiError(error));
  }
}

function* onGetCurrentUser() {
  try {
    const response = yield call(getCurrentUser);
    if (response.status === "failure") {
      return yield put(ACTION.getCurrentUserFail(response.message));
    }

    yield put(ACTION.getCurrentUserSuccess(response));
  } catch (error) {
    yield put(ACTION.getCurrentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { navigate, role } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.clear();
    yield put(ACTION.logoutUserSuccess());
    // navigate.push("/");
    window.location.replace("/");
  } catch (error) {
    yield put(ACTION.apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.GET_CURRENT_USER, onGetCurrentUser);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
}

export default authSaga;
