import * as TYPES from "./actionTypes";

const INIT_STATE = {
  loading: false,
  notificationList: [],
  appMessageList: [],
  allStocks: [],
  openPositionList: [],
  pendingPositionList: [],
  scheduleOrderList: [],
  historyOrderList: [],
  tradingActivityList: [],
  stockView: {},
  error: "",
};

const Reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: action?.payload,
      };
    case TYPES.GET_MESSAGES:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        appMessageList: action?.payload,
      };

    case TYPES.ADD_HELP_SUPPORT:
      return {
        ...state,
        loading: true,
      };

    case TYPES.ADD_HELP_SUPPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.ADD_HELP_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.ALL_STOCK_LIST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.ALL_STOCK_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.ALL_STOCK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allStocks: action?.payload?.data,
      };
    case TYPES.OPEN_POSITIONS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.OPEN_POSITIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.OPEN_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        openPositionList: action?.payload?.data,
      };

    case TYPES.PENDING_POSITIONS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.PENDING_POSITIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.PENDING_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingPositionList: action?.payload?.data,
      };

    case TYPES.SCHEDULE_ORDER:
      return {
        ...state,
        loading: true,
      };

    case TYPES.SCHEDULE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.SCHEDULE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleOrderList: action?.payload?.data,
      };
    case TYPES.STOCK_HISTORY_ORDER:
      return {
        ...state,
        loading: true,
      };

    case TYPES.STOCK_HISTORY_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.STOCK_HISTORY_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        historyOrderList: action?.payload,
      };

    case TYPES.TRADING_ACTIVITIES:
      return {
        ...state,
        loading: true,
      };
    case TYPES.TRADING_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.TRADING_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        tradingActivityList: action?.payload,
      };

    case TYPES.STOCK_SINGLE_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case TYPES.STOCK_SINGLE_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.STOCK_SINGLE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        stockView: action?.payload?.data,
      };
    default:
      return state;
  }
};

export default Reducer;
