import React from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";

// css
import styles from "./myWallet.module.scss";

// images
import g1 from "../../../Assets/images/g1.png";
import { Link } from "react-router-dom";
import { CounterCard } from "../../../components/Common/Common";
import HistoricalTransaction from "./tabbed/HistoricalTransaction";
import ActivityLog from "./tabbed/ActivityLog";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Loader";

const MyWallet = () => {
  const { userProfile, loadingProfileProfile } = useSelector((state) => state?.Profile);

  return (
    <>
    {loadingProfileProfile && 
      <Loader /> 
    }
      <section className="myWallet py-2 position-relative">
        <Container>
          <Row>
            <Col lg="8" className="my-2">
              <div className="py-2">
                <CounterCard  userProfile={userProfile} />
              </div>
              <div className="py-2">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="HistoricalTransactions"
                >
                  <Nav
                    variant="pills"
                    className={`${styles.tabList} underlineTab gap-10`}
                    style={{
                      borderBottom: "1px solid var(--darkLightGray)",
                    }}
                  >
                    <Nav.Item className="px-1">
                      <Nav.Link
                        className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2
                        fw-sbold `}
                        eventKey="HistoricalTransactions"
                      >
                        Historical Transactions
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="px-1">
                      <Nav.Link
                        className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2
                        fw-sbold `}
                        eventKey="ActivityLogs"
                      >
                        Activity Logs
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="HistoricalTransactions">
                      <HistoricalTransaction />
                    </Tab.Pane>
                    <Tab.Pane eventKey="ActivityLogs">
                      <ActivityLog />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
            <Col lg="4" className="my-2">
              {/* <div className="py-2">
                <div
                  className="box position-relative rounded d-flex align-items-start justify-content-between gap-10 p-2"
                  style={{
                    background: "var(--ThemeDarkGray)",
                    fontSize: 12,
                    lineHeight: "20px",
                  }}
                >
                  <div className="left d-flex align-items-center gap-10 flex-wrap">
                    <div className="imgWrp">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54"
                        height="54"
                        viewBox="0 0 54 54"
                        fill="none"
                      >
                        <rect width="54" height="54" rx="5" fill="#2A2A2E" />
                        <path
                          d="M20.25 21.125C19.6172 21.125 19.125 21.6523 19.125 22.25V23.6562L25.9805 28.7188C26.5781 29.1406 27.3867 29.1406 27.9844 28.7188L34.875 23.6562V22.25C34.875 21.6523 34.3477 21.125 33.75 21.125H20.25ZM19.125 25.0625V31.25C19.125 31.8828 19.6172 32.375 20.25 32.375H33.75C34.3477 32.375 34.875 31.8828 34.875 31.25V25.0625L28.6523 29.6328C27.668 30.3359 26.2969 30.3359 25.3125 29.6328L19.125 25.0625ZM18 22.25C18 21.0195 18.9844 20 20.25 20H33.75C34.9805 20 36 21.0195 36 22.25V31.25C36 32.5156 34.9805 33.5 33.75 33.5H20.25C18.9844 33.5 18 32.5156 18 31.25V22.25Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="content">
                      <p className="m-0 fw-bold darkLightRed">
                        Verify your email address
                      </p>
                      <p className="text-muted m-0">
                        Your email verification is pending
                      </p>
                    </div>
                  </div>
                  <Button
                    className="d-flex align-items-center justify-content-center border-0 p-0"
                    style={{ background: "#474747", height: 30, width: 30 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.377 11.7344L5.73633 7.05859L1.06055 11.7344C0.849609 11.9453 0.498047 11.9453 0.251953 11.7344C0.0410156 11.4883 0.0410156 11.1367 0.251953 10.9258L4.92773 6.25L0.251953 1.60938C0.0410156 1.39844 0.0410156 1.04688 0.251953 0.800781C0.498047 0.589844 0.849609 0.589844 1.06055 0.800781L5.73633 5.47656L10.377 0.800781C10.5879 0.589844 10.9395 0.589844 11.1855 0.800781C11.3965 1.04688 11.3965 1.39844 11.1855 1.60938L6.50977 6.25L11.1855 10.9258C11.3965 11.1367 11.3965 11.4883 11.1855 11.7344C10.9395 11.9453 10.5879 11.9453 10.377 11.7344Z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                </div>
              </div> */}
              <div className="py-2">
                <div className="box text-center position-relative p-3 px-lg-4">
                  <p className=" font-light m-0">Dana Utama</p>
                  <h2 className="m-0 fw-bold  py-1">$2.125.000</h2>
                  <p className="themeGreen fw-sbold m-0">
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M11.7102 6.71C11.6172 6.80373 11.5066 6.87812 11.3848 6.92889C11.2629 6.97966 11.1322 7.0058 11.0002 7.0058C10.8682 7.0058 10.7375 6.97966 10.6156 6.92889C10.4937 6.87812 10.3831 6.80373 10.2902 6.71L7.00019 3.41V11C7.00019 11.2652 6.89483 11.5196 6.70729 11.7071C6.51976 11.8946 6.2654 12 6.00019 12C5.73497 12 5.48062 11.8946 5.29308 11.7071C5.10554 11.5196 5.00019 11.2652 5.00019 11V3.41L1.71019 6.71C1.52188 6.8983 1.26649 7.00409 1.00019 7.00409C0.733884 7.00409 0.478489 6.8983 0.290185 6.71C0.101882 6.5217 -0.00390625 6.2663 -0.00390625 6C-0.00390625 5.7337 0.101882 5.4783 0.290185 5.29L5.29019 0.290001C5.38529 0.19896 5.49743 0.127594 5.62019 0.0799999C5.73989 0.0270948 5.86931 -0.000232697 6.00019 -0.000232697C6.13106 -0.000232697 6.26049 0.0270948 6.38019 0.0799999C6.50294 0.127594 6.61508 0.19896 6.71019 0.290001L11.7102 5.29C11.8039 5.38296 11.8783 5.49356 11.9291 5.61542C11.9798 5.73728 12.006 5.86799 12.006 6C12.006 6.13201 11.9798 6.26272 11.9291 6.38458C11.8783 6.50644 11.8039 6.61704 11.7102 6.71Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    $206.920 (10,6%)
                  </p>
                  <div className="btnWrpper mt-3">
                    <Button className="my-2 d-flex align-items-center justify-content-center rounded-pill whiteBordered commonBtn w-100">
                      Deposit
                    </Button>
                    <Link
                      to="/my-wallet/withdrawal"
                      className="my-2 d-flex align-items-center justify-content-center rounded-0 commonBtn btn btn-primary w-100 rounded-pill"
                    >
                      Withdraw
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MyWallet;
