import { takeLatest, put, call } from "redux-saga/effects";

import * as TYPES from "./actionTypes";
import * as ACTION from "./actions";
import * as API from "helpers/backend_helper";

function* onGetHome({ payload: { data, callback } }) {
  try {
    const response = yield call(API.homeAPI, data);
    if (response.status === "failure") {
      return yield put(ACTION.getHomeFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.getHomeSuccess(response.data));
  } catch (error) {
    yield put(ACTION.getHomeFail(error));
  }
}

function* onTopGainer({ payload: { data, callback } }) {
  try {
    const response = yield call(API.topGainerApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.topGainerFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.topGainerSuccess(response.data));
  } catch (error) {
    yield put(ACTION.topGainerFail(error));
  }
}

function* onTopLooser({ payload: { data, callback } }) {
  try {
    const response = yield call(API.topLooserApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.topLooserFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.topLooserSuccess(response.data));
  } catch (error) {
    yield put(ACTION.topLooserFail(error));
  }
}

function* onIndustry({ payload: { data, callback } }) {
  try {
    const response = yield call(API.industryApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.industriesFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.industriesSuccess(response.data));
  } catch (error) {
    yield put(ACTION.industriesFail(error));
  }
}

function* onPopularStock({ payload: { data, callback } }) {
  try {
    const response = yield call(API.popularStockApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.popularStockFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.popularStockSuccess(response.data));
  } catch (error) {
    yield put(ACTION.popularStockFail(error));
  }
}

function* onFavouriteStock({ payload: { data, callback } }) {
  try {
    const response = yield call(API.favouriteStockApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.favouriteStockFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.favouriteStockSuccess(response.data));
  } catch (error) {
    yield put(ACTION.favouriteStockFail(error));
  }
}

function* onAddRemoveFavouriteStock({ payload: { data, callback } }) {
  try {
    const response = yield call(API.addRemovefavouriteStockApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.addRemoveFavouriteStockFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.addRemoveFavouriteStockSuccess(response.data));
  } catch (error) {
    yield put(ACTION.addRemoveFavouriteStockFail(error));
  }
}

function* Saga() {
  yield takeLatest(TYPES.GET_HOME, onGetHome);
  yield takeLatest(TYPES.TOP_GAINER, onTopGainer);
  yield takeLatest(TYPES.TOP_LOOSER, onTopLooser);
  yield takeLatest(TYPES.INDUSTRIES, onIndustry);
  yield takeLatest(TYPES.POPULARSTOCK, onPopularStock);
  yield takeLatest(TYPES.FAVOURITE_STOCK, onFavouriteStock);
  yield takeLatest(TYPES.ADD_REMOVE_FAVOURITE_STOCK, onAddRemoveFavouriteStock);
}
export default Saga;
