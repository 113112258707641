import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const TransactionDetail = () => {
  const [step, setStep] = useState(1);
  return (
    <>
      <section className="transactionDetail py-3 position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap  gap-10">
                <div className="left d-flex align-items-center gap-10">
                  <Link to={"/my-wallet/withdrawal-exchange"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                    >
                      <path
                        d="M15.4141 8.086L3.82806 8.086L8.32806 12.586L6.91406 14L6.07329e-05 7.086L6.91406 0.172001L8.32806 1.586L3.82806 6.086L15.4141 6.086L15.4141 8.086Z"
                        fill="var(--darkLightRed)"
                      />
                    </svg>
                  </Link>
                  <h5 className="m-0 fw-sbold ">Transaction Details</h5>
                </div>
              </div>
            </Col>
            {step == 1 ? (
              <>
                <Col lg="12" className="my-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    <li className="py-1">
                      <div className="d-flex align-items-center justify-content-between gap-10 box py-3 px-3">
                        <p className="m-0  fw-sbold">Withdraw Money</p>
                        <p className="m-0 text-muted fw-sbold">$ 12.00</p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center justify-content-between gap-10 box py-3 px-3">
                        <p className="m-0  fw-sbold">Exchange Fee</p>
                        <p className="m-0 text-muted fw-sbold">$ 0.04 (0.3%)</p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center justify-content-between gap-10 box py-3 px-3">
                        <p className="m-0  fw-sbold">FX Rate</p>
                        <p className="m-0 text-muted fw-sbold">RP 16.162.00</p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center justify-content-between gap-10 box py-3 px-3">
                        <p className="m-0  fw-sbold">Amount Charged IDR</p>
                        <p className="m-0 text-muted fw-sbold">$ 12.04</p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center justify-content-between gap-10 box py-3 px-3">
                        <p className="m-0  fw-sbold">Amount Charged IDR</p>
                        <p className="m-0 text-muted fw-sbold">
                          RP 194,525.83{" "}
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="d-flex align-items-center justify-content-between gap-10 box py-3 px-3">
                        <p className="m-0  fw-sbold">IDR Amount Received</p>
                        <p className="m-0 text-muted fw-sbold">RP 193,944.00</p>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col lg="12" className="my-2">
                  <div className="text-center">
                    <div className="py-2">
                      <Button
                        onClick={() => setStep(2)}
                        className="d-inline-flex align-items-center justify-content-center commonBtn"
                        style={{ height: 50, minWidth: 150 }}
                      >
                        Withdraw
                      </Button>
                    </div>
                    <div className="py-2">
                      <Button
                        className="border-0 p-0  fw-sbold"
                        variant="transparent"
                        style={{ height: 50, minWidth: 150 }}
                      >
                        I’ll withdraw later
                      </Button>
                    </div>
                  </div>
                </Col>
              </>
            ) : step == 2 ? (
              <>
                <Col lg="12" className="my-2">
                  <div className="box ">
                    <div className="contentBody px-3 py-3">
                      <div className="d-flex align-items-start gap-10">
                        <div className="icnWrp flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                          >
                            <circle
                              cx="20"
                              cy="20"
                              r="19"
                              fill=""
                              stroke="#8F8689"
                              stroke-width="2"
                            />
                            <path
                              d="M23.4379 25.2988H16.4839V26.6896H23.4379V25.2988Z"
                              fill="#8F8689"
                            />
                            <path
                              d="M22.0474 28.0801H17.875V29.4709H22.0474V28.0801Z"
                              fill="#8F8689"
                            />
                            <path
                              d="M19.9609 10C18.1166 10 16.3478 10.7326 15.0437 12.0368C13.7396 13.3409 13.0069 15.1097 13.0069 16.954C12.9599 17.9646 13.1535 18.972 13.5718 19.8932C13.9901 20.8144 14.6211 21.6232 15.413 22.2529C16.1084 22.8996 16.4839 23.2682 16.4839 23.908H17.8747C17.8747 22.6284 17.1028 21.9122 16.3518 21.2237C15.7018 20.726 15.1838 20.0765 14.8432 19.3321C14.5025 18.5878 14.3495 17.7712 14.3977 16.954C14.3977 15.4785 14.9838 14.0635 16.0271 13.0202C17.0704 11.9769 18.4854 11.3908 19.9609 11.3908C21.4363 11.3908 22.8514 11.9769 23.8947 13.0202C24.938 14.0635 25.5241 15.4785 25.5241 16.954C25.5714 17.7718 25.4174 18.5887 25.0755 19.3332C24.7336 20.0776 24.2143 20.7268 23.5631 21.2237C22.819 21.9191 22.0471 22.6145 22.0471 23.908H23.4379C23.4379 23.2682 23.8064 22.8996 24.5088 22.246C25.3001 21.6173 25.9309 20.8098 26.3492 19.8897C26.7675 18.9697 26.9614 17.9636 26.9149 16.954C26.9149 16.0408 26.735 15.1365 26.3855 14.2928C26.0361 13.4491 25.5238 12.6825 24.8781 12.0368C24.2324 11.391 23.4658 10.8788 22.6221 10.5293C21.7784 10.1799 20.8741 10 19.9609 10Z"
                              fill="#8F8689"
                            />
                          </svg>
                        </div>
                        <div className="content">
                          <p className="m-0 text-muted fw-sbold">
                            Your transaction is currently under processing.
                          </p>
                          <p className="m-0  text-muted fw-sbold">
                            You will receive a notification once the transaction
                            is completed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div
                    className="d-flex align-items-center mt-5 text-white justify-content-center w-100 py-3 px-2  rounded btn fw-sbold"
                    style={{ background: "#007F00", fontSize: 12 }}
                  >
                    In your account withdrawal amount successfully
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TransactionDetail;
