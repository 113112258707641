import Login from "./AuthPages/Login/Index";
import EmailVerification from "./AuthPages/emailVerification";
import ForgotPassword from "./AuthPages/forgot/Index";
import SignUp from "./AuthPages/Signup";
import Dashboard from "./SideTabPages/Dashboard";
import Setting from "./SideTabPages/setting";
import Support from "./SideTabPages/Support";
import StockHistory from "./SideTabPages/StockHistory";
import FavouriteStock from "./SideTabPages/FavouriteStock";
import FavouriteStockDetail from "./SideTabPages/FavouriteStockDetail";
import IndustryDetail from "./SideTabPages/IndustryDetail";
import MyPortFolio from "./SideTabPages/MyPortFolio";
import Transaction from "./SideTabPages/MyWallet/Transaction";
import WalletPage from "./SideTabPages/MyWallet/Wallet";
import CustomerProfile from "./AuthPages/customerProfile";
import MyWallet from "./SideTabPages/MyWallet";
import StockDetail from "./SideTabPages/stockDetail";
import TopStockDetail from "./SideTabPages/TopStockDetail";
import PopularStockDetail from "./SideTabPages/PopularStockDetail";
import StockCompare from "./SideTabPages/stockCompare";
import ResetPassword from "./AuthPages/ResetPassword";
import Withdrawal from "./SideTabPages/MyWallet/Withdrawal";
import Logout from "./AuthPages/Logout";
import BankTransfer from "./SideTabPages/MyWallet/BankTransfer";
import AddBank from "./SideTabPages/MyWallet/BankTransfer/AddBank";
import WithdrawalExchange from "./SideTabPages/MyWallet/withdrawalExchange";
import TransactionDetail from "./SideTabPages/MyWallet/TransactionDetail";
import TermsCondition from "./AuthPages/customerProfile/Component/TermsCondition";
import StockCompleteDetails from "components/StockCompleteDetails";

export const routes = [
  { path: "/", component: <Login /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/customer-profile", component: <CustomerProfile /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/email-verification", component: <EmailVerification /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/terms-conditions", component: <TermsCondition /> },
];

export const privateRoutes = [
  { path: "/home", component: <Dashboard /> },
  { path: "/setting", component: <Setting /> },
  { path: "/support", component: <Support /> },
  { path: "/stock-history", component: <StockHistory /> },
  { path: "/favorite-stock", component: <FavouriteStock /> },
  { path: "/favorite-stock-detail", component: <FavouriteStockDetail /> },

  { path: "/my-portfolio", component: <MyPortFolio /> },
  { path: "/my-wallet/transaction", component: <Transaction /> },
  { path: "/my-wallet/wallet", component: <WalletPage /> },
  { path: "/my-wallet", component: <MyWallet /> },
  { path: "/my-wallet/withdrawal", component: <Withdrawal /> },
  { path: "/my-wallet/bank-transfer", component: <BankTransfer /> },
  { path: "/my-wallet/bank-transfer/add", component: <AddBank /> },
  { path: "/my-wallet/withdrawal-exchange", component: <WithdrawalExchange /> },
  { path: "/my-wallet/transaction-detail", component: <TransactionDetail /> },
  { path: "/stock-detail", component: <StockDetail /> },
  {
    path: "/stock-complete-details/:symbol",
    component: <StockCompleteDetails />,
  },
  { path: "/industry-detail", component: <IndustryDetail /> },
  { path: "/popular-stock-detail", component: <PopularStockDetail /> },
  { path: "/top-stock-detail", component: <TopStockDetail /> },
  { path: "/stock-compare", component: <StockCompare /> },
  { path: "/logout", component: <Logout /> },
];
