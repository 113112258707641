import React from "react";
import { Link, useNavigate } from "react-router-dom";

const TopGainers = ({ topGainerList }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="box p-0 h-100">
        <div className="table-responsive commonTable">
          <table className="table">
            <thead>
              <tr>
                <th className="bg-transparent  fw-sbold">Currency</th>
                <th className="bg-transparent  fw-sbold">Price</th>
                <th className="bg-transparent  fw-sbold">24 Change</th>
                <th className="bg-transparent fw-sbold">Volume</th>
              </tr>
            </thead>
            <tbody>
              {topGainerList?.length > 0 &&
                topGainerList?.slice(0, 1).map((list, i) => {
                  return (
                    <>
                      <tr
                        style={{ cursor: "pointer" }}
                        className="bg-transparent"
                        key={i}
                        onClick={() => {
                          navigate("/stock-complete-details/" + list?.symbol);
                          // setSelectedItem(list); setStockList("detail")
                        }}
                      >
                        <td className="bg-transparent rounded-0">
                          <div
                            // onClick={() => {
                            //   navigate(
                            //     "/stock-complete-details/" + list?.symbol
                            //   );
                            //   // setSelectedItem(list); setStockList("detail")
                            // }}
                            className="d-flex align-items-start gap-10"
                          >
                            <div className="icn">
                              {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="40"
                            height="39"
                            viewBox="0 0 40 39"
                            fill="none"
                          >
                            <path
                              d="M19.8536 37.9356C30.0813 37.9356 38.3831 29.6936 38.3831 19.5137C38.3831 9.33378 30.0813 1.0918 19.8536 1.0918C9.62593 1.0918 1.32422 9.33378 1.32422 19.5137C1.32422 29.6936 9.62593 37.9356 19.8536 37.9356Z"
                              fill="url(#pattern0_0_1777)"
                              stroke="#474747"
                              stroke-width="2"
                            />
                            <path
                              d="M19.854 37.5283C29.803 37.5283 37.8682 29.4631 37.8682 19.5142C37.8682 9.56521 29.803 1.5 19.854 1.5C9.90506 1.5 1.83984 9.56521 1.83984 19.5142C1.83984 29.4631 9.90506 37.5283 19.854 37.5283ZM34.8854 19.5142C34.8854 27.8158 28.1556 34.5456 19.854 34.5456C11.5524 34.5456 4.82258 27.8158 4.82258 19.5142C4.82258 11.2125 11.5524 4.48274 19.854 4.48274C28.1556 4.48274 34.8854 11.2125 34.8854 19.5142Z"
                              fill="#E2E2E2"
                              stroke="#474747"
                            />
                            <defs>
                              <pattern
                                id="pattern0_0_1777"
                                patternContentUnits="objectBoundingBox"
                                width="1"
                                height="1"
                              >
                                <use
                                  xlinkHref="#image0_0_1777"
                                  transform="matrix(0.00591588 0 0 0.00595238 -0.387381 0)"
                                />
                              </pattern>
                              <image
                                width="300"
                                height="168"
                                src={list?.imgUrl}
                              />
                            </defs>
                          </svg> */}
                              <img
                                style={{ height: 30, width: 30 }}
                                src={list?.imgUrl}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="content">
                              <p className="m-0 fw-sbold">{list?.symbol}</p>
                              <p className="text-muted m-0 fw-sbold">
                                {list?.shortName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="bg-transparent rounded-0">
                          $
                          {list?.price
                            ? parseFloat(list?.price).toFixed(2)
                            : "0"}
                        </td>
                        <td className="bg-transparent rounded-0">
                          <p class="m-0 themeGreen fw-sbold">
                            <span
                              style={{
                                color: list?.change?.toString().includes("-")
                                  ? "red"
                                  : "green",
                              }}
                            >
                              {list?.change
                                ? `${list.change > 0 ? "+" : ""}${parseFloat(
                                    list.change
                                  ).toFixed(2)}`
                                : "0.00"}
                            </span>
                            <span
                              style={{
                                color: list?.changePercentage
                                  ?.toString()
                                  .includes("-")
                                  ? "red"
                                  : "green",
                              }}
                            >
                              (
                              {list?.changePercentage
                                ? `${
                                    list.changePercentage > 0 ? "+" : ""
                                  }${parseFloat(list.changePercentage).toFixed(
                                    2
                                  )}%`
                                : "0.00"}
                              )
                            </span>{" "}
                            <span
                              class="icn ms-1"
                              style={{
                                color: list?.change?.toString().includes("-")
                                  ? "red"
                                  : "green",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 12 7"
                                fill="none"
                              >
                                <path
                                  d="M10.6512 6.76367L1.34122 6.76367C1.17643 6.76364 1.01534 6.7123 0.878337 6.61615C0.741328 6.52 0.634545 6.38336 0.571487 6.2235C0.508429 6.06363 0.491926 5.88773 0.524066 5.71802C0.556206 5.54831 0.635545 5.39242 0.752052 5.27005L5.40705 0.382297C5.56332 0.21826 5.77525 0.126108 5.99622 0.126108C6.21719 0.126108 6.42911 0.21826 6.58539 0.382297L11.2404 5.27005C11.3569 5.39242 11.4362 5.54831 11.4684 5.71802C11.5005 5.88773 11.484 6.06363 11.421 6.22349C11.3579 6.38336 11.2511 6.52 11.1141 6.61615C10.9771 6.7123 10.816 6.76363 10.6512 6.76367Z"
                                  fill="#00B833"
                                ></path>
                              </svg>
                            </span>
                          </p>
                        </td>
                        <td className="bg-transparent rounded-0">
                          <span className="icn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="80"
                              height="23"
                              viewBox="0 0 80 23"
                              fill="none"
                            >
                              <path
                                d="M1.32422 6.07552L2.19089 7.39502C3.05755 8.71452 4.79089 11.3535 6.52422 10.954C8.25755 10.5545 9.99089 7.11646 11.7242 8.94252C13.4576 10.7686 15.1909 17.8588 16.9242 20.6057C18.6576 23.3527 20.3909 21.7564 22.1242 18.2918C23.8576 14.8272 25.5909 9.49431 27.3242 6.22302C29.0576 2.95173 30.7909 1.74206 32.5242 4.08685C34.2576 6.43165 35.9909 12.3309 37.7242 14.7694C39.4576 17.2079 41.1909 16.1856 42.9242 15.9275C44.6576 15.6693 46.3909 16.1753 48.1242 16.3157C49.8576 16.4561 51.5909 16.231 53.3242 14.5201C55.0576 12.8093 56.7909 9.6127 58.5242 7.93252C60.2576 6.25235 61.9909 6.08857 63.7242 5.01277C65.4576 3.93697 67.1909 1.94916 68.9242 1.28709C70.6576 0.625021 72.3909 1.28869 74.1242 1.48479C75.8576 1.6809 77.5909 1.40944 78.4576 1.27371L79.3242 1.13798"
                                stroke="#FF0000"
                                stroke-width="2"
                              />
                            </svg>
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TopGainers;
