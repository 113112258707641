import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import styles from "./setting.module.scss";
import InviteFriend from "./component/tabbed/InviteFriend";
import FaqTab from "./component/tabbed/FaqTab";
import PolicyDisclosure from "./component/tabbed/PolicyDisclosure";
import EditProfile from "./component/tabbed/EditProfile";
import { NavLink, useNavigate } from "react-router-dom";
// img
import p1 from "../../../Assets/images/logo-placeholder.png";
import NotificationTab from "./component/tabbed/NotificationTab";
import InAppMessage from "./component/tabbed/InAppMessage";
import TrustedContact from "./component/tabbed/TrustedContact";
import SettingTab from "./component/tabbed/SettingTab";
//store
import { getProfile } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { dateFormat } from "helpers/helper";
import {
  uploadFile,
  putProfile,
  getNotification,
  getAppMessages,
} from "store/actions";
import Loader from "components/Loader";
import BecomeAnAffiliate from "./component/tabbed/BecomeAnAffiliate";
import AffiliateArea from "./component/tabbed/AffiliateArea";

const Setting = () => {
  const navigate = useNavigate();
  const { loadingProfile } = useSelector((state) => state.Profile);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [editCoverImg, setEditCoverImg] = useState("");
  const [isCopied, sertIsCopied] = useState(false);
  const { userProfile } = useSelector((state) => state?.Profile);
  const { notificationList, appMessageList, loading } = useSelector(
    (state) => state?.Basic
  );

  
  const handleChooseImageClick = () => {
    fileInputRef.current.click(); // Trigger the file input when the button is clicked
  };
  useEffect(() => {
    // dispatch(getProfile())
  }, []);

  //upload cover image
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    const callback = (response) => {
      const { data } = response;
      setEditCoverImg(data?.url);
      const callbackFunc = (response) => {
        const { message } = response;
        if (response.status == "success") {
          toast.success(message);
        }
      };
      let obj = {
        profileImage: data?.url,
      };
      dispatch(putProfile(obj, callbackFunc));
    };
    dispatch(uploadFile(selectedFile, callback));
  };
  useEffect(() => {
    if (userProfile?.profileImage) {
      setEditCoverImg(userProfile?.profileImage);
    }
  }, [userProfile?.profileImage]);

  useEffect(() => {
    dispatch(getNotification());
    dispatch(getAppMessages());
    // setImmediate()
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      sertIsCopied(false);
    }, 2000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [isCopied]);

  const copyToClipboard = (textToCopy) => {
    // Create a temporary textarea to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    // Select the text and copy it
    textarea.select();
    document.execCommand("copy");
    // Remove the temporary textarea
    document.body.removeChild(textarea);
    sertIsCopied(true);
  };

  return (
    <>
      {(loading || loadingProfile) && <Loader />}
      <section className={`${styles.Setting} Setting py-2 position-relative`}>
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="pageHead mb-4">
                <h5 className="m-0  fw-sbold">Profile Settings</h5>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Row className="justify-content-between align-items-center">
                <Col lg="5" md="5" sm="6">
                  <div className="py-2">
                    <div className="d-flex align-items-center gap-10 ">
                      <div
                        className="imgWrp upload position-relative flex-shrink-0"
                        style={{ height: 120, width: 120 }}
                      >
                        <input
                          type="file"
                          accept="image/*" // Specify the allowed file types (e.g., images)
                          className="file position-absolute w-100 h-100 rounded-circle"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileInputChange}
                        />

                        <img
                          src={editCoverImg ? editCoverImg : p1}
                          alt=""
                          className="img-fluid rounded-circle h-100 w-100 object-fit-cover"
                        />
                        <span
                          className="icn position-absolute"
                          style={{ right: 10, bottom: 0, cursor: "pointer" }}
                          onClick={handleChooseImageClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                          >
                            <circle cx="13" cy="13" r="13" fill="white" />
                            <g clip-path="url(#clip0_29_60)">
                              <path
                                d="M15.1875 13.4375C15.1875 14.0177 14.957 14.5741 14.5468 14.9843C14.1366 15.3945 13.5802 15.625 13 15.625C12.4198 15.625 11.8634 15.3945 11.4532 14.9843C11.043 14.5741 10.8125 14.0177 10.8125 13.4375C10.8125 12.8573 11.043 12.3009 11.4532 11.8907C11.8634 11.4805 12.4198 11.25 13 11.25C13.5802 11.25 14.1366 11.4805 14.5468 11.8907C14.957 12.3009 15.1875 12.8573 15.1875 13.4375Z"
                                fill="black"
                              />
                              <path
                                d="M7.75 9.5C7.28587 9.5 6.84075 9.68437 6.51256 10.0126C6.18437 10.3408 6 10.7859 6 11.25V16.5C6 16.9641 6.18437 17.4092 6.51256 17.7374C6.84075 18.0656 7.28587 18.25 7.75 18.25H18.25C18.7141 18.25 19.1592 18.0656 19.4874 17.7374C19.8156 17.4092 20 16.9641 20 16.5V11.25C20 10.7859 19.8156 10.3408 19.4874 10.0126C19.1592 9.68437 18.7141 9.5 18.25 9.5H17.2245C16.7604 9.4999 16.3154 9.31546 15.9872 8.98725L15.2628 8.26275C14.9346 7.93454 14.4896 7.7501 14.0255 7.75H11.9745C11.5104 7.7501 11.0654 7.93454 10.7372 8.26275L10.0128 8.98725C9.68464 9.31546 9.23959 9.4999 8.7755 9.5H7.75ZM8.1875 11.25C8.07147 11.25 7.96019 11.2039 7.87814 11.1219C7.79609 11.0398 7.75 10.9285 7.75 10.8125C7.75 10.6965 7.79609 10.5852 7.87814 10.5031C7.96019 10.4211 8.07147 10.375 8.1875 10.375C8.30353 10.375 8.41481 10.4211 8.49686 10.5031C8.57891 10.5852 8.625 10.6965 8.625 10.8125C8.625 10.9285 8.57891 11.0398 8.49686 11.1219C8.41481 11.2039 8.30353 11.25 8.1875 11.25ZM16.0625 13.4375C16.0625 14.2497 15.7398 15.0287 15.1655 15.603C14.5912 16.1773 13.8122 16.5 13 16.5C12.1878 16.5 11.4088 16.1773 10.8345 15.603C10.2602 15.0287 9.9375 14.2497 9.9375 13.4375C9.9375 12.6253 10.2602 11.8463 10.8345 11.272C11.4088 10.6977 12.1878 10.375 13 10.375C13.8122 10.375 14.5912 10.6977 15.1655 11.272C15.7398 11.8463 16.0625 12.6253 16.0625 13.4375Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_29_60">
                                <rect
                                  width="14"
                                  height="14"
                                  fill="white"
                                  transform="translate(6 6)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                      <div className="content">
                        <h5 className="m-0 text-white fw-bold">
                          {userProfile?.first_name
                            ? userProfile?.first_name
                            : userProfile?.name}{" "}
                          {userProfile?.last_name}
                        </h5>
                        <p className="m-0 text-white py-2">
                          Last Updated on {dateFormat(userProfile?.updatedAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <Form>
                      <label htmlFor="" className="form-label fw-sbold ">
                        Account Number
                      </label>
                      <div className="position-relative iconWithText">
                        <input
                          type="text"
                          value={userProfile?.accountData?.account_number}
                          className="form-control py-2 text-muted fw-bold box rounded h-auto"
                        />
                        <Button
                          style={{ right: 10 }}
                          variant="transparent"
                          className="border-0 p-0 icn position-absolute"
                          onClick={() =>
                            copyToClipboard(
                              userProfile?.accountData?.account_number
                            )
                          }
                        >
                          {!isCopied ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              fill="none"
                            >
                              <path
                                d="M0.999999 4.9L1 12.7C1 13.418 1.5794 14 2.29412 14L8.76471 14M4.88235 1L10.7059 1C11.4206 1 12 1.58201 12 2.3L12 10.1C12 10.818 11.4206 11.4 10.7059 11.4L4.88235 11.4C4.16763 11.4 3.58824 10.818 3.58824 10.1L3.58823 2.3C3.58823 1.58201 4.16763 1 4.88235 1Z"
                                stroke="#919191"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            "copied"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col lg="4" md="5" sm="6">
                  <ul className="list-unstyled ps-0 mb-0 text-end">
                    <li className="py-1">
                      <NavLink to="/logout">
                        <Button
                          className="border-0 d-inline-flex align-items-center p-0 currentColor fw-sbold"
                          variant="transparent"
                        >
                          <span className="icn me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <path
                                d="M15.5833 6.41667L14.2908 7.70917L16.6558 10.0833H7.33325V11.9167H16.6558L14.2908 14.2817L15.5833 15.5833L20.1666 11L15.5833 6.41667ZM3.66659 4.58333H10.9999V2.75H3.66659C2.65825 2.75 1.83325 3.575 1.83325 4.58333V17.4167C1.83325 18.425 2.65825 19.25 3.66659 19.25H10.9999V17.4167H3.66659V4.58333Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          Logout
                        </Button>
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <Button
                        className="border-0 d-inline-flex align-items-center p-0 themeClr fw-sbold"
                        variant="transparent"
                      >
                        Close Account
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="my-2 pt-4">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="ProfileInformation"
                
              >
                <Nav
                  variant="pills"
                  className={`${styles.tabList} mb-3 underlineTab gap-10  overflow-scroll flex-nowrap` }
                  style={{ borderBottom: "1px solid var(--darkLightGray)" ,   }}
                >
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="ProfileInformation"
                    >
                      Profile Information
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="Notifications"
                    >
                      Notifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="InAppMessages"
                    >
                      InApp Messages
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="TrustedContact"
                    >
                      Trusted Contact
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="Settings"
                    >
                      Settings
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="PoliciesDisclosures"
                    >
                      Policies and Disclosures
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="becomeAnAffiliate"
                    >
                      Become an Affiliate
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-1 text-nowrap">
                    <Nav.Link
                      className={`${styles.link} bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold `}
                      eventKey="affiliateArea"
                    >
                      Affiliate Area
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="ProfileInformation">
                    <EditProfile userProfile={userProfile} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Notifications">
                    <NotificationTab notificationList={notificationList} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="InAppMessages">
                    <InAppMessage appMessageList={appMessageList} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="TrustedContact">
                    <TrustedContact />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Settings">
                    <SettingTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="PoliciesDisclosures">
                    <PolicyDisclosure />
                  </Tab.Pane>
                  <Tab.Pane eventKey="becomeAnAffiliate">
                    <BecomeAnAffiliate />
                  </Tab.Pane>
                  <Tab.Pane eventKey="affiliateArea">
                    <AffiliateArea />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Setting;
