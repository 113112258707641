import { CONSTANT } from "./constant";

export const limitOrderTypesListStatic = [
  {
    label: "GTC",
    value: "gtc",
  },
  {
    label: "DAY",
    value: "day",
  },
];

export const purchaseTypesStatic = [
  {
    _id: 1,
    label: 'Share',
    value: 'Share',
  },
  {
    _id: 2,
    label: 'Money',
    value: 'Money',
  },
];

export const orderTypesStatic = [
  {
    _id: 1,
    label: 'Market',
    value: 'market',
  },
  {
    _id: 2,
    label: 'Limit',
    value: 'limit',
  },
];


export const stockDetailsStatic = {
  _id: 1,
  name: 'Tesla',
  symbol: 'TSLA',
  lastPrice: 620.35,
  currentPrice: 315,
  percent_change: -0.72,
  open: 119.3,
  high: 120.36,
  low: 116.29,
  '52WkHigh': 274.29,
  '52WkLow': 108.7,
  Volume: '9.25M',
  AvgVol: '25.25M',
  MarketCap: '341.80B',
  PERatio: '17.61',
  DivYield: '',
  news: {
    _id: 1,
    // image: IMAGES.DEMO_PIC_RANDOM,

    name: 'Benzinga',
    headline:
      'Why Tasla, JD and Other Tech  Stocks are Crashing in Hong Kong Today',
  },
  // image: IMAGES.demoStock2,
  price: 620.35,
  fiveYearData: {
    _id: 1,
    buyPrice: 525.0,
    holdPrice: 525.0,
    sellPrice: 960,
  },
  buyDate: '23/11/2023',
  sellDate: '23/11/2023',
};


export const buyItEveryDayListStatic = [
  {
    _id: 1,
    label: '10 AM',
    time: '10:00',
  },
  {
    _id: 2,
    label: '11 AM',
    time: '11:00',
  },
  {
    _id: 3,
    label: '12 PM',
    time: '12:00',
  },
  {
    _id: 4,
    label: '1 PM',
    time: '13:00',
  },
  {
    _id: 5,
    label: '2 PM',
    time: '14:00',
  },
  {
    _id: 6,
    label: '3 PM',
    time: '15:00',
  },
  {
    _id: 7,
    label: '4 PM',
    time: '16:00',
  },
];

export const buyItWeeklyListStatic = [
  {
    _id: 1,
    label: 'Monday - 10 AM',
    day: 'Monday',
    time: '10:00',
  },
  {
    _id: 2,
    label: 'Monday - 11 AM',
    day: 'Monday',
    time: '11:00',
  },
  {
    _id: 3,
    label: 'Monday - 12 PM',
    day: 'Monday',
    time: '12:00',
  },
  {
    _id: 4,
    label: 'Monday - 1 PM',
    day: 'Monday',
    time: '13:00',
  },
  {
    _id: 5,
    label: 'Monday - 2 PM',
    day: 'Monday',
    time: '14:00',
  },
  {
    _id: 6,
    label: 'Monday - 3 PM',
    day: 'Monday',
    time: '15:00',
  },
  {
    _id: 7,
    label: 'Monday - 4 PM',
    day: 'Monday',
    time: '16:00',
  },
  {
    _id: 8,
    label: 'Tuesday - 10 AM',
    day: 'Tuesday',
    time: '10:00',
  },
  {
    _id: 9,
    label: 'Tuesday - 11 AM',
    day: 'Tuesday',
    time: '11:00',
  },
  {
    _id: 10,
    label: 'Tuesday - 12 PM',
    day: 'Tuesday',
    time: '12:00',
  },
  {
    _id: 11,
    label: 'Tuesday - 1 PM',
    day: 'Tuesday',
    time: '13:00',
  },
  {
    _id: 12,
    label: 'Tuesday - 2 PM',
    day: 'Tuesday',
    time: '14:00',
  },
  {
    _id: 13,
    label: 'Tuesday - 3 PM',
    day: 'Tuesday',
    time: '15:00',
  },
  {
    _id: 14,
    label: 'Tuesday - 4 PM',
    day: 'Tuesday',
    time: '16:00',
  },
  {
    _id: 15,
    label: 'Wednesday - 10 AM',
    day: 'Wednesday',
    time: '10:00',
  },
  {
    _id: 16,
    label: 'Wednesday - 11 AM',
    day: 'Wednesday',
    time: '11:00',
  },
  {
    _id: 17,
    label: 'Wednesday - 12 PM',
    day: 'Wednesday',
    time: '12:00',
  },
  {
    _id: 18,
    label: 'Wednesday - 1 PM',
    day: 'Wednesday',
    time: '13:00',
  },
  {
    _id: 19,
    label: 'Wednesday - 2 PM',
    day: 'Wednesday',
    time: '14:00',
  },
  {
    _id: 20,
    label: 'Wednesday - 3 PM',
    day: 'Wednesday',
    time: '15:00',
  },
  {
    _id: 21,
    label: 'Wednesday - 4 PM',
    day: 'Wednesday',
    time: '16:00',
  },
  {
    _id: 22,
    label: 'Thursday - 10 AM',
    day: 'Thursday',
    time: '10:00',
  },
  {
    _id: 23,
    label: 'Thursday - 11 AM',
    day: 'Thursday',
    time: '11:00',
  },
  {
    _id: 24,
    label: 'Thursday - 12 PM',
    day: 'Thursday',
    time: '12:00',
  },
  {
    _id: 25,
    label: 'Thursday - 1 PM',
    day: 'Thursday',
    time: '13:00',
  },
  {
    _id: 26,
    label: 'Thursday - 2 PM',
    day: 'Thursday',
    time: '14:00',
  },
  {
    _id: 27,
    label: 'Thursday - 3 PM',
    day: 'Thursday',
    time: '15:00',
  },
  {
    _id: 28,
    label: 'Thursday - 4 PM',
    day: 'Thursday',
    time: '16:00',
  },
  {
    _id: 29,
    label: 'Friday - 10 AM',
    day: 'Friday',
    time: '10:00',
  },
  {
    _id: 30,
    label: 'Friday - 11 AM',
    day: 'Friday',
    time: '11:00',
  },
  {
    _id: 31,
    label: 'Friday - 12 PM',
    day: 'Friday',
    time: '12:00',
  },
  {
    _id: 32,
    label: 'Friday - 1 PM',
    day: 'Friday',
    time: '13:00',
  },
  {
    _id: 33,
    label: 'Friday - 2 PM',
    day: 'Friday',
    time: '14:00',
  },
  {
    _id: 34,
    label: 'Friday - 3 PM',
    day: 'Friday',
    time: '15:00',
  },
  {
    _id: 35,
    label: 'Friday - 4 PM',
    day: 'Friday',
    time: '16:00',
  },
];
export const buyItMonthlyListStatic = [
  {
    _id: 1,
    label: 'Every First Monday of the Month',
    day: 'Monday',
    time: '16:00',
    timeType: 'First',
  },
  {
    _id: 2,
    label: 'Every First Wednesday of the Month',
    day: 'Wednesday',
    time: '16:00',
    timeType: 'First',
  },
  {
    _id: 3,
    label: 'Every First Friday of the Month',
    day: 'Friday',
    time: '16:00',
    timeType: 'First',
  },
  {
    _id: 4,
    label: 'Every Last Monday of the Month',
    day: 'Monday',
    time: '16:00',
    timeType: 'Last',
  },
  {
    _id: 5,
    label: 'Every Last Wednesday of the Month',
    day: 'Wednesday',
    time: '16:00',
    timeType: 'Last',
  },
  {
    _id: 6,
    label: 'Every Last Friday of the Month',
    day: 'Friday',
    time: '16:00',
    timeType: 'Last',
  },
];



export const scheduleOrderListStatic = [
  {
    _id: 1,
    label: 'Buy it every Day',
    value: CONSTANT.DAILY,
    isSelected: false,
  },
  {
    _id: 2,
    label: 'Buy it every Week',
    value: CONSTANT.WEEKLY,
    isSelected: false,
  },
  {
    _id: 3,
    label: 'Buy it every Month',
    value: CONSTANT.MONTHLY,
    isSelected: false,
  },
];
