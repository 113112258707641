import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

// css
import styles from "./stockCompare.module.scss";

const StockCompare = () => {
  return (
    <>
      <section
        className={`${styles.StockCompare} stockCompare py-3 position-relative`}
      >
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="pageHead py-2">
                <h5 className="m-0 text-white">
                  ICICI Bank vs HDFC Bank Stock Compare
                </h5>
                <p className="m-0 py-2 text-muted">
                  Below is the stock comparison of ICICI Bank vs HDFC Bank
                </p>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Form>
                <div className="py-2">
                  <label htmlFor="" className="form-label text-white m-0 pb-1">
                    Enter Your Symbol
                  </label>
                  <input
                    type="text"
                    className="form-control border-secondary"
                  />
                </div>
                <div className="py-2">
                  <div
                    className={`${styles.btnWrpper} btnWrpper d-flex align-items-center gap-10`}
                  >
                    <div
                      className={`${styles.btn} btn d-flex align-items-center justify-content-between gap-10 px-2 commonBtn`}
                    >
                      <p className="m-0 text-white fw-sbold">ICICI Bank ltd.</p>
                      <Button variant="transparnet" className="border-0 p-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                        >
                          <path
                            d="M8.28 2.94L10.36 -9.53674e-07H11.88V0.0399997L7.14 6.84L12.2 13.96V14H10.56L8.28 10.76L6.22 7.68L4.04 10.9L1.84 14H0.24V13.96L5.3 6.84L0.56 0.0399997V-9.53674e-07H2.12L4.24 3.02L6.22 5.98L8.28 2.94Z"
                            fill="white"
                          />
                        </svg>
                      </Button>
                    </div>
                    <div
                      className={`${styles.btn} btn d-flex align-items-center justify-content-between gap-10 px-2 commonBtn`}
                    >
                      <p className="m-0 text-white fw-sbold">HDFC Bank ltd.</p>
                      <Button variant="transparnet" className="border-0 p-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                        >
                          <path
                            d="M8.28 2.94L10.36 -9.53674e-07H11.88V0.0399997L7.14 6.84L12.2 13.96V14H10.56L8.28 10.76L6.22 7.68L4.04 10.9L1.84 14H0.24V13.96L5.3 6.84L0.56 0.0399997V-9.53674e-07H2.12L4.24 3.02L6.22 5.98L8.28 2.94Z"
                            fill="white"
                          />
                        </svg>
                      </Button>
                    </div>
                    <Button
                      className={`${styles.btn} btn d-flex align-items-center justify-content-center fw-sbold gap-10 px-2 commonBtn`}
                    >
                      Compare Stocks
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
            <Col lg="12" className="my-2">
              <div
                className="cardCstm box border-0"
                style={{ background: "#222" }}
              >
                <div className="py-2 d-flex align-items-center justify-content-between gap-10 px-3">
                  <p className="m-0 text-white fw-sbold">Price Information</p>
                </div>
                <div className="py-2">
                  <div className="table-responsive">
                    <table className={`${styles.strippedTable} table`}>
                      <thead className="">
                        <tr style={{ background: "#181818" }}>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            Exchange
                          </th>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            NYSE
                          </th>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            NYSE
                          </th>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            NYSE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Open
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            High
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Low
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Last
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            253.94
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Change
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            <p className="m-0 themeClr">-1.96</p>
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            <p className="m-0 themeClr">-1.96</p>
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            <p className="m-0 themeClr">-1.96</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            % Change
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            <p className="m-0 themeClr">--0.71%</p>
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            <p className="m-0 themeClr">--0.71%</p>
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            <p className="m-0 themeClr">--0.71%</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Volume
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            1,077,200
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            1,077,200
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            1,077,200
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div
                className="cardCstm box border-0"
                style={{ background: "#222" }}
              >
                <div className="py-2 d-flex align-items-center justify-content-between gap-10 px-3">
                  <p className="m-0 text-white fw-sbold">Result</p>
                  <Button className="border-0 p-0" variant="transparent">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="18"
                      viewBox="0 0 24 18"
                      fill="none"
                    >
                      <path
                        d="M21 11.25V14.25C21 14.6478 20.7893 15.0294 20.4142 15.3107C20.0391 15.592 19.5304 15.75 19 15.75H5C4.46957 15.75 3.96086 15.592 3.58579 15.3107C3.21071 15.0294 3 14.6478 3 14.25V11.25"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 7.5L12 11.25L17 7.5"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 11.25V2.25"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </div>
                <div className="py-2">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="">
                        <tr style={{ background: "#181818" }}>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            Symbol
                          </th>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            AAP
                          </th>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            KMX
                          </th>
                          <th className="m-0 text-white border-0 py-3 px-3">
                            HGTY
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Company
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Advance Auto Parts Inc
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Carmax Inc
                          </td>
                          <td className="m-0 text-white border-0 py-3 px-3">
                            Hagerty Inc
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default StockCompare;
