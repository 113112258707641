import React from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddBank = () => {
  return (
    <>
      <section className="AddBank position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap  gap-10">
                <div className="left d-flex align-items-center gap-10">
                  <Link to={"/my-wallet/bank-transfer"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                    >
                      <path
                        d="M15.4141 8.086L3.82806 8.086L8.32806 12.586L6.91406 14L6.07329e-05 7.086L6.91406 0.172001L8.32806 1.586L3.82806 6.086L15.4141 6.086L15.4141 8.086Z"
                        fill="var(--darkLightRed)"
                      />
                    </svg>
                  </Link>
                  <h5 className="m-0 fw-sbold ">Add Bank Account</h5>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <p className="m-0 fw-sbold ">Bank Account</p>
                <p className="m-0 text-muted">
                  ADD THIS AFTER IMPLEMENTING PAYMNET METHOD
                </p>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Form>
                <Row>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Account Holder Name
                    </label>
                    <input type="text" className="form-control box" />
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Account Number
                    </label>
                    <input type="number" className="form-control box" />
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Bank Name
                    </label>
                    <input type="text" className="form-control box" />
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Bank Country
                    </label>
                    <Form.Select
                      className="form-control box"
                      aria-label="Default select example"
                    >
                      <option>Indonesia</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Bank Name
                    </label>
                    <Form.Select
                      className="form-control box"
                      aria-label="Default select example"
                    >
                      <option>Indonesia</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Col>
                  <Col lg="12" className="my-2">
                    <div className="btnWrpper text-end mt-4">
                      <Button
                        className="d-inline-flex align-items-center justify-content-center commonBtn"
                        style={{ height: 50, minWidth: 150 }}
                      >
                        Add Bank Account
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddBank;
