import React, { createContext, useState, useContext, useEffect } from "react";

const ThemeContext = createContext();

const lightTheme = {
  "--ThemeBg": "#ffffff",
  "--ThemeDarkGray": "#e7e7e7",
  "--backgroundColor": "#F2F2F2",
  "--darkLight": "#010101",
  "--darkLightGray": "#a7a7a74d",
  "--darkLightRed": "#cf0a10",
};

const darkTheme = {
  "--ThemeBg": "#010101",
  "--ThemeDarkGray": "#272727",
  "--backgroundColor": "#080606",
  "--darkLight": "#fff",
  "--darkLightGray": "#ffffff4d",
  "--darkLightRed": "#fff",
};

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleTheme = (checked) => {
    setIsDarkMode(checked);
  };

  useEffect(() => {
    const theme = isDarkMode ? darkTheme : lightTheme;
    for (const key in theme) {
      document.documentElement.style.setProperty(key, theme[key]);
    }
    if (isDarkMode) {
      document.body.classList.add("darkTheme");
    } else {
      document.body.classList.remove("darkTheme");
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
